import { createRouter, createWebHistory } from 'vue-router';
//配置组件和跳转url之间的关系
const routes = [
  {
    name: 'login',
    path: '/',
    component: () => import('@/components/LoginCmp')
  },
  {
    name: 'qrLogin',
    path: '/qr/:code',
    component: () => import('@/components/QrLogin')
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})
export default router
