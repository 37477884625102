import { createStore } from 'vuex'
export default createStore({
    state: {
      isLogin: 'no'
    },
    getters: {
      
    },
    mutations: {
      change(s) {
        if(s.isLogin === 'no') {
          s.isLogin = 'yes'
        } else {
          s.isLogin = 'no'
        }
      }
    },
    actions: {

    }
})